@import "~./node_modules/bootstrap/scss/bootstrap";

$dark-grey: #2e2a2b;
$grey: #4f4c4d;
$light-grey: #efeff4;
$red: #d31b29;
$white: #fff;
$montserrat: "Montserrat",
sans-serif;
$roboto: "Roboto",
sans-serif;

html {
  scroll-behavior: smooth;
}

body {
  background-color: $light-grey;
  margin: 0;
  min-height: 100%;
}

.button__whatsapp {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 80px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #fff;
  }
}

.pt-sm-11 {
  padding-top: 4rem !important;
}

.section__title {
  text-align: left;
  font-weight: 600;
  font-family: $montserrat;
  color: $dark-grey;
  font-size: 38px;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  line-height: 1.2;
  position: relative;
  margin-bottom: 1.8rem;

  &::after {
    position: absolute;
    content: "";
    width: 130px;
    height: 4px;
    background-color: $red;
    left: 0;
    bottom: -5px;
  }
}

/* Header */
.header {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  background-color: transparent;
  z-index: 1000;

  .nav {
    min-height: 90px;

    .navbar-brand {
      img {
        max-width: 110px;
      }
    }

    .navbar-toggler {
      &:focus {
        outline: none;
        box-shadow: none;
      }

      span {
        display: block;
        width: 33px;
        height: 4px;
        margin-bottom: 5px;
        position: relative;
        background: $dark-grey;
        border-radius: 3px;
        z-index: 1;
        transform-origin: 4px 0px;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
        /*  */

        &:first-child {
          transform-origin: 0% 0%;
        }

        &:nth-last-child(2) {
          transform-origin: 0% 100%;
        }
      }
    }

    .nav-link {
      color: $dark-grey;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      font-family: $montserrat;
      margin: 0.5rem 0.8rem;
      position: relative;

      &::after {
        content: "";
        display: block;
        width: 0;
        height: 2px;
        background: $red;
        transition: width 0.3s;
      }

      &:hover {
        &::after {
          width: 100%;
        }
      }
    }
  }
}

.hero {
  background-color: $light-grey;
  height: 100vh;

  &__title {
    text-align: left;
    font-weight: 900;
    font-family: $montserrat;
    color: $dark-grey;
    font-size: 42px;
    text-transform: uppercase;
    letter-spacing: 1.4px;
    line-height: 1.2;
    margin-bottom: 1.5rem;
  }

  &__sub-title {
    text-align: left;
    font-weight: 400;
    font-family: $roboto;
    color: $grey;
    font-size: 24px;
    text-transform: inherit;
    line-height: 1;
  }

  &__button {
    background-color: $red;
    text-transform: uppercase;
    color: #fff;
    margin-top: 1.5rem;
    border-radius: 6px;

    &:hover {
      color: #fff;
    }
  }

  &__container-image {
    position: relative;
    background: url(../assets/logistica_femori.jpg) no-repeat;
    height: 450px;
    border-radius: 8px;

    &::after {
      position: absolute;
      content: "";
      background-color: #2e2a2b;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      border-radius: 8px;
    }
  }
}

.services {
  .card {
    border-radius: 14px;
    margin: 0 0 45px 0;
    min-height: 200px;
    height: auto;

    .card-body {
      padding: 1.3rem;

      .card-title {
        text-align: left;
        font-weight: 600;
        font-family: $roboto;
        color: $red;
        font-size: 18px;
        text-transform: inherit;
        line-height: 1.2;
        text-transform: uppercase;
        height: 40px;
      }

      .card-text {
        text-align: left;
        font-weight: 400;
        font-family: $roboto;
        color: $grey;
        font-size: 16px;
      }
    }
  }

  &__routes {
    &-list {
      li {
        margin-bottom: 0.5rem;
        list-style-image: url("../assets/map-outline.svg");
      }
    }
  }
}

.about-us {
  &__text {
    text-align: left;
    font-weight: 400;
    font-family: $roboto;
    color: $grey;
    font-size: 18px;
    text-transform: inherit;
    line-height: 1;
  }

  &__carousel {
    .img-fluid {
      border-radius: 8px;
    }
  }
}

.our-clients {
  .slick-initialized .slick-list {
    padding: 0 0 30px 0;
  }

  .slide-arrow {
    position: absolute;
    top: 50%;
    margin-top: -15px;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  
    &.prev-arrow {
      left: -40px;
      width: 25px;
      height: 25px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;;
      background: url(../assets/chevron-back-outline.svg);
      opacity: 0.6;
    }
  
    &.next-arrow {
      right: -40px;
      width: 25px;
      height: 25px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background: url(../assets/chevron-forward-outline.svg);
      opacity: 0.6;
    }
  }

  &__title {
    text-align: center;

    &::after {
      display: none;
    }
  }

  &__card {
    max-width: 180px;
    height: 110px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    margin: auto;
    padding: 1.2rem;
    border-radius: 14px;
    background-color: #fff;
  }
}

.contact {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 200px;
    background-color: $dark-grey;
    z-index: -1;
  }

  &__title {
    color: #fff;

    &::after {
      display: none;
    }
  }

  &__address {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    p {
      text-align: left;
      font-weight: 400;
      font-family: $montserrat;
      color: $dark-grey;
      font-size: 21px;
      text-transform: inherit;
      line-height: 1;
      margin-bottom: 0.5rem;

      &:first-child {
        font-size: 24px;
        font-weight: 600;
      }

      a {
        text-decoration: none;
        color: $dark-grey;
      }
    }
  }

  &__social {
    padding-top: 1rem;

    &-facebook {
      font-size: 34px;
      color: $grey;
      transition: all 0.3s ease-in-out;
      margin-right: 15px;

      &:hover {
        color: #3b5998;
      }
    }

    &-instagram {
      font-size: 34px;
      color: $grey;
      transition: all 0.3s ease-in-out;

      &:hover {
        //fill: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
        color: #c13584;
      }
    }
  }

  &__form {
    padding: 1.5rem;
    background-color: #fff;
    border-radius: 14px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    min-height: 450px;
    font-family: $roboto;

    .btn {
      background-color: $red;
      text-transform: uppercase;
      width: 110px;
      border-color: $red;
      border-radius: 6px;
    }
  }
}

.footer {
  background-color: $dark-grey;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .img-fluid {
      max-width: 100px;
    }

    p {
      text-align: left;
      font-weight: 400;
      font-family: $roboto;
      color: #fff;
      font-size: 14px;
      text-transform: inherit;
      line-height: 1;
      margin: 0;
    }
  }
}

.slick-slide {
  &:focus {
    outline: none;
  }
}

.slick-dots {
  li {
    &.slick-active {
      button {
        &::before {
          transform: scale(1.3);
          color: $red;
        }
      }
    }

    button {
      &::before {
        font-size: 10px;
      }
    }
  }
}

/* Mobile */

@media (min-width: 768px) {
  .hero {
    padding-top: 250px !important;
  }

  .pt-md-11 {
    padding-top: 6rem !important;
  }

  .py-md-11 {
    padding: 6rem 0 !important;
  }

  .services {
    .card {
      min-height: 240px;
    }
  }
}